import type {
  ActionFunction,
  LinksFunction,
  LoaderFunction,
} from '@remix-run/node';
import { json } from '@remix-run/node';
import { AuthorizationError } from 'remix-auth';

import UserLogin, {
  links as UserLoginLinks,
} from '~/components/user-login/user-login';
import authenticator from '~/services/auth.server';
import { getSessionError } from '~/services/sessions.server';

export const links: LinksFunction = () => [...UserLoginLinks()];

export const loader: LoaderFunction = async ({ request }) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/chats',
  });

  const error = await getSessionError(request);

  return json({ error });
};

export const action: ActionFunction = async ({ request, context }) => {
  try {
    const response = await authenticator.authenticate('form', request, {
      successRedirect: '/chats',
      throwOnError: true,
      context,
    });

    return response;
  } catch (error) {
    if (error instanceof Response) return error;
    if (error instanceof AuthorizationError) {
      return json({ error: error?.message });
    }
  }
};

const Index = () => <UserLogin />;

export default Index;
