import { anonymousLogo, Button, TextField } from '@libs/ui';
import type { LinksFunction } from '@remix-run/node';
import { Form, Link, useActionData, useNavigation } from '@remix-run/react';

import styles from './user-login.css';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

const UserLogin = () => {
  const { error = '' } = useActionData<{error: string;}>() ?? {};
  const { state, formMethod } = useNavigation();

  const isSubmitting =
  state === 'submitting' || state === 'loading' && formMethod != null;

  return (
    <div className="user-login">
      <div className="user-login__wrapper">
        <div className="user-login__logo">
          <img src={anonymousLogo} alt="Anonymous" />
        </div>
        <h1>
          <div className="user-login__header">Welcome to</div>
          <div className="user-login__sub-header">Anonymous Chat</div>
        </h1>
        <Form method="post" className="user-login__form">
          {error && <p className="user-login__error">Invalid access code</p>}
          <div className="user-login__inputs">
            <TextField
              label="Enter access code"
              name="accessCode"
              type="password"
              error={!!error}
              required />

            <Button disabled={isSubmitting} type="submit">
              {isSubmitting ? 'JOINING' : 'JOIN'}
            </Button>
            <p className="user-login__info">
              Are you a leader?{' '}
              <Link to="/leader" className="user-login__link">
                Login
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </div>);

};

export default UserLogin;